import React from "react"
import { Map, Marker, TileLayer } from "react-leaflet"

const map = ({ position }) => {
  if (typeof window !== "undefined") {
    return (
      <Map center={position} zoom={13}>
        <TileLayer
          url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />
        <Marker position={position} />
      </Map>
    )
  }
  return null
}

export default map
